/* eslint-disable comma-dangle */
/* eslint-disable semi */
import { createSlice } from "@reduxjs/toolkit";
import { editRiver, getRivers, getSingleRiver } from "./river.thunk";

const initialState = {
  isLoading: false,
  singleViewData : null,
  data: [],
  error: null,
};
const riverSlice = createSlice({
  name: "river",
  initialState,
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRivers.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getRivers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.error = null;
    });
    builder.addCase(getRivers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.data = [];
    });
    builder.addCase(getSingleRiver.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.singleViewData = null
    });
    builder.addCase(getSingleRiver.fulfilled, (state, action) => {
      state.isLoading = false;
      state.singleViewData = action.payload.data;
      state.error = null;
    });
    builder.addCase(getSingleRiver.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.singleViewData = null
    });

    builder.addCase(editRiver.pending, () => {
    });
    builder.addCase(editRiver.fulfilled, (state, action) => {
      state.singleViewData = action.payload.data;
    });
    builder.addCase(editRiver.rejected, (state, action) => {
      state.error = action.error.message;
    });

  },
});
export const { selectEvent } = riverSlice.actions;

export default riverSlice.reducer;
