/* eslint-disable comma-dangle */
/* eslint-disable semi */
import authHttp from "./authHttp";

export const getAllLakes = async () => {
  const url = `api/lakes`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getSingleLakes = async (id) => {
  const url = `api/lakes/${id}`;
  const { data } = await authHttp.post(url);
  return data;
};

export const editLakes = async (sendData) => {
  try {
    const url = `api/editLakes`;
    const { data } = await authHttp.post(url, sendData);
    return data;
  } catch (err) {
    throw err;
  }
};
