/* eslint-disable comma-dangle */
/* eslint-disable semi */
import { createAsyncThunk } from "@reduxjs/toolkit";
// import { Check, X } from "react-feather";
// import toast from "react-hot-toast";
import {
  getAllLakes, getSingleLakes
} from "../../services/lakes.services";
// import ToastContent from "../../views/components/toast/toast";

export const getLakes = createAsyncThunk("lakes/get", async () => {
  return await getAllLakes();
});

export const getSingleLake = createAsyncThunk("lakes/single/get", async (id) => {
  return await getSingleLakes(id);
});