// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import riverSlice from './riverSlice/river'
import lakesSlice from './lakesSlice/lakes'
const rootReducer = {
  navbar,
  auth,
  riverSlice,
  lakesSlice,
  layout
}

export default rootReducer
