/* eslint-disable comma-dangle */
/* eslint-disable semi */
import { createSlice } from "@reduxjs/toolkit";
import { getLakes, getSingleLake } from "./lakes.thunk";

const initialState = {
  isLoading: false,
  data: [],
  error: null,
  singleData : null
};
const lakesSlice = createSlice({
  name: "lakes",
  initialState,
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLakes.pending, (state) => {
      state.isLoading = true;
      state.singleData = null
    });
    builder.addCase(getLakes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.error = null;
    });
    builder.addCase(getLakes.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.data = [];
    });

    builder.addCase(getSingleLake.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSingleLake.fulfilled, (state, action) => {
      state.isLoading = false;
      state.singleData = action.payload.data;
      state.error = null;
    });
    builder.addCase(getSingleLake.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.singleData = null
    });

  },
});
export const { selectEvent } = lakesSlice.actions;

export default lakesSlice.reducer;
