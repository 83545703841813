/* eslint-disable comma-dangle */
/* eslint-disable semi */
import authHttp from "./authHttp";

export const getAllRivers = async () => {
  const url = `api/rivers`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getSingleViewRiver = async (Id) => {
  const url = `api/rivers/${Id}`;
  const { data } = await authHttp.post(url);
  return data;
};

export const editRivers = async (sendData) => {
  try {
    const url = `api/editRiver`;
    const { data } = await authHttp.post(url, sendData);
    return data;
  } catch (err) {
    throw err;
  }
};
