/* eslint-disable comma-dangle */
/* eslint-disable semi */
import { createAsyncThunk } from "@reduxjs/toolkit";
// import { Check, X } from "react-feather";
// import toast from "react-hot-toast";
import { editRivers, getAllRivers, getSingleViewRiver } from "../../services/river.services";
// import ToastContent from "../../views/components/toast/toast";

export const getRivers = createAsyncThunk("river/get", async () => {
  return await getAllRivers();
});

export const getSingleRiver = createAsyncThunk("riversingle/get", async (id) => {
  return await getSingleViewRiver(id);
});

export const editRiver = createAsyncThunk("river/edit", async (id) => {
  return await editRivers(id);
});